//== Colors
$blue-light: #01a1dd;
$blue-dark: #015e84;

$text-light: #ffffff;


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   #6d6e71; // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           #e9e9e9; // #eee

// $brand-primary:         darken(#428bca, 6.5%); // #337ab7
$brand-primary:         #01a1dd;
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
// $text-color:            $gray-dark;
$text-color:            $gray-base;

//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Lato", sans-serif;
// $font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          14px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1:            floor(($font-size-base * 3.3)); // ~46px
$font-size-h2:            floor(($font-size-base * 2.9)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


// *, :after, :before {
//     box-sizing: border-box;
// }
// html{ -webkit-font-smoothing: antialiased; }
// button {
//     -webkit-font-smoothing: inherit;
// }

//== Utilities
.margin-top-xs-20 {
  margin-top: 20px;
}
.margin-t-20 {
  margin-top: 20px;
}

.padding-t-15 {
  padding-top: 15px;
}
.padding-t-20 {
  padding-top: 20px;
}

.padding-tb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-tb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-t-30 {
  padding-top: 30px;
}

.text-blue-light {
  color: $blue-light;
}

.text-large {
  font-size: 4em;
  @media (min-width: $screen-sm-min) {
     font-size: 5.3em;
  }
}
.text-left-sm {
  @media (max-width: $screen-sm) {
    text-align: left;
  }
}
.text-left-xs {
  @media (max-width: $screen-xs) {
    text-align: left;
  }
}
.text-medium {
  font-size: 1.3em;
}
.header {
  background-image: url('../images/spacer-header.gif');
  background-repeat: no-repeat;
  background-position: center top;
}
.padding-top-xs-10 {
  // border-left-width: 0;
  @media (max-width: $screen-sm) {
     padding-top: 10px;
  }
}

.visible-header-block {
  display: block !important;
  @media (min-width: 530px) {
    display: none !important;
  }
}

.img-responsive-inline {
  display: inline-block;
}


ul.content-list {
  padding-left: 15px;
  margin-left: 0;
}


.Border-left {
  border-left: 1px solid $gray-base;
}
.Border-right {
  border-right: 1px solid $gray-base;
}
.Border-right--xs-hidden {
  border-right-width: 0;
  @media (min-width: $screen-sm-min) {
     border-right-width: 1px;
  }
}
.Border-left--xs-hidden {
  border-left-width: 0;
  @media (min-width: $screen-sm-min) {
     border-left-width: 1px;
  }
}

.Border--light-blue {
  border-color: $blue-light;
}

.Background-photo {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.Background-photo--homes {
  background-image: url('../images/background-homes.jpg');
  height: 516px;
}

.Background-photo--roofing {
  background-image: url('../images/background-top.jpg');
  height: 331px;
}

.Background-color--light-blue {
  background-color: $blue-light;
  color: $text-light;
}

.Background-color--dark-blue {
  background-color: $blue-dark;
  color: $text-light;
}

.Background-color--gray {
  background-color: $gray;
  color: $text-light;

  .help-block {
    color: $text-light;
  }
}

.Background-color--gray-lighter {
  background-color: $gray-lighter;
}

.Background-pattern {

}
.Background-pattern--light {

}
.Background-pattern--gray {
  background-image: url('../images/background-pattern--gray.gif');
}
.Background-pattern--gray-lighter {
  background-image: url('../images/background-pattern--gray-lighter.gif');
}



.ContactInfo {

}

.ContactInfo__name {
  font-size: $font-size-h4;
  @media (min-width: $screen-lg-min) {
    font-size: 30px;
  }
}

.ContactInfo__position {
  font-size: $font-size-h4;
}

.ContactInfo__telephone {
  font-size: $font-size-h4;
  @media (min-width: $screen-lg-min) {
    font-size: $font-size-h3;
  }
}

.ContactInfo__email {
  font-size: $font-size-h4;
  @media (min-width: $screen-lg-min) {
    font-size: $font-size-h3;
  }
}

.ContactInfo__rbq {

}

.Copyright {
  // height: 200px;
  padding-bottom: 40px;
  color: #5c5c5c;
}

.CopyrightEnergik {
  background-image: url('../images/copyright-energik.gif');
  background-repeat: no-repeat;
  background-position: top right;
  height: 73px;
  position: relative;
}
.CopyrightEnergik__item {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 180px;
}


